import '@styles/app.pcss';
import main from '@commons/main';
import header from '@commons/header';

export default function init() {
  // Commons
  header();
  main();
}

init();
